import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import { Outlet, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkAuthenticated } from '../actions/auth'

const MainLayout = ({ isAuthenticated, checkAuthenticated }) => {
  return (
    <>
      <Navbar />
      <main className='h-full overflow-hidden'>
        <Outlet />
      </main>
    </>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { checkAuthenticated })(MainLayout)
