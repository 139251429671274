import Cookies from 'js-cookie'
import axios from 'axios'

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  VERIFY_AUTHENTICATION_SUCCESS,
  VERIFY_AUTHENTICATION_FAIL
} from './types'

export const checkAuthenticated = () => async dispatch => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/auth/verify/`,
      config
    )

    if (res.data.success && res.data.isAuthenticated) {
      console.log('case 1')
      dispatch({
        type: VERIFY_AUTHENTICATION_SUCCESS,
        payload: res.data.isAuthenticated
      })
    } else if (res.data.success && !res.data.isAuthenticated) {
      console.log('case 2')
      dispatch({
        type: VERIFY_AUTHENTICATION_FAIL,
        payload: res.data.isAuthenticated
      })
    } else {
      console.log('case 3')
      dispatch({
        type: VERIFY_AUTHENTICATION_FAIL,
        payload: false
      })
    }

    console.log(
      'DATA',
      res.data.success,
      res.data.message,
      res.data.isAuthenticated
    )
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL
    })
  }
}

export const login = password => async dispatch => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
  }

  console.log('CSRFTOKEN', Cookies.get('csrftoken'))

  const body = JSON.stringify({ password })

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login/`,
      body,
      config
    )

    console.log('RESPONSE', res.data)

    if (res.data.success) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      })
      // Load the settings
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: res.data.message
      })
    }
  } catch (error) {
    console.log('ERROR', error)
    dispatch({
      type: LOGIN_FAIL,
      payload: error.message
    })
  }
}

export const logout = () => async dispatch => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    }
  }

  const body = JSON.stringify({
    withCredentials: true
  })

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/logout/`,
      body,
      config
    )
    if (res.data.success) {
      dispatch({
        type: LOGOUT_SUCCESS
      })
      // Load the settings
    } else {
      dispatch({
        type: LOGOUT_FAIL
      })
    }
  } catch (error) {
    dispatch({
      type: LOGOUT_FAIL
    })
  }
}
