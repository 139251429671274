import React, { useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkAuthenticated } from '../actions/auth'

const AuthGuard = ({ isAuthenticated, checkAuthenticated }) => {
  useEffect(() => {
    checkAuthenticated()
  }, [checkAuthenticated])

  return isAuthenticated ? <Outlet /> : <Navigate to='/login' />
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { checkAuthenticated })(AuthGuard)
