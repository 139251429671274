import React, { useState, useEffect } from 'react'

const PosPage = () => {
  const [products, setProducts] = useState([
    {
      id: 1,
      name: 'ប្រេងឆា Healthy Chef',
      retail_uom: 'ដប',
      retail_price: 2,
      wholesale_price: 10,
      wholesale_uom: 'កេស'
    },
    {
      id: 2,
      name: 'ទឹកសុទ្ធ Crystal',
      retail_uom: 'ដប',
      retail_price: 1,
      wholesale_price: 5,
      wholesale_uom: 'កេស'
    },
    {
      id: 3,
      name: 'សាប៊ូ Lux',
      retail_price: 3,
      wholesale_price: 8
    }
  ])

  const [order, setOrder] = useState({
    state: 'draft',
    exchange_rate: 4000,
    orderLines: [],
    total_khr: 0,
    total_usd: 0
  })

  const [orderLines, setOrderLines] = useState([])

  const clearOrder = () => {
    setOrderLines([])
  }

  // Add an item to the orderLines
  const addItem = (sales_type, product) => {
    setOrderLines(prevOrderLines => {
      // Check if the product already exists in the order lines
      const existingLine = prevOrderLines.find(
        line => line.id === product.id && line.sales_type === sales_type
      )

      if (existingLine) {
        // If it exists, increase the quantity
        return prevOrderLines.map(line =>
          line.id === product.id && line.sales_type === sales_type
            ? { ...line, quantity: line.quantity + 1 }
            : line
        )
      }

      // If it doesn't exist, add a new order line
      const newLine = {
        id: product.id,
        name: product.name,
        sales_type: sales_type,
        unit_price:
          sales_type === 'retail'
            ? product.retail_price * 4100
            : product.wholesale_price * 4100,
        quantity: 1
      }

      return [...prevOrderLines, newLine]
    })
  }

  const removeLine = order_line => {
    setOrderLines(prevOrderLines =>
      prevOrderLines.filter(
        line =>
          line.id !== order_line.id || line.sales_type !== order_line.sales_type
      )
    )
  }

  // Increase the quantity of an order line
  const increaseQty = order_line => {
    setOrderLines(prevOrderLines =>
      prevOrderLines.map(line =>
        line.id === order_line.id && line.sales_type === order_line.sales_type
          ? { ...line, quantity: line.quantity + 1 }
          : line
      )
    )
  }

  const modifyQty = (value, order_line) => {
    console.log('changing', value)
    // Ensure value is a positive integer
    const parsedValue = parseInt(value, 10)
    if (isNaN(parsedValue) || parsedValue <= 0) {
      return // Prevent invalid or negative quantities
    }

    setOrderLines(prevOrderLines =>
      prevOrderLines.map(line =>
        line.id === order_line.id && line.sales_type === order_line.sales_type
          ? { ...line, quantity: parsedValue }
          : line
      )
    )
  }

  // Decrease the quantity of an order line
  const decreaseQty = order_line => {
    setOrderLines(prevOrderLines => {
      return prevOrderLines
        .map(line =>
          line.id === order_line.id && line.sales_type === order_line.sales_type
            ? { ...line, quantity: line.quantity - 1 }
            : line
        )
        .filter(line => line.quantity > 0) // Remove the line if quantity reaches 0
    })
  }

  const [search, setSearch] = useState('')
  const [filteredProducts, setFilteredProducts] = useState(products)

  useEffect(() => {
    const totalKHR = orderLines.reduce(
      (acc, line) => acc + line.unit_price * line.quantity,
      0
    )
    setOrder(prev => ({
      ...prev,
      total_khr: totalKHR,
      total_usd: totalKHR / order.exchange_rate
    }))

    const searchProducts = search => {
      if (!search.trim()) {
        setFilteredProducts(products) // If search is empty, show all products
        return
      }

      const lowerCaseSearch = search.toLowerCase()
      const filtered = products.filter(product =>
        product.name.toLowerCase().includes(lowerCaseSearch)
      )
      setFilteredProducts(filtered)
    }

    searchProducts(search)
  }, [search, products, orderLines, order.exchange_rate])

  return (
    <div className='h-full flex gap-4 rounded justify-between items-start px-5 pt-4 pb-2'>
      <section className='w-[60%] h-full rounded-md flex flex-col gap-4'>
        <div className='w-full flex rounded-md border-2 overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 192.904 192.904'
            width='16px'
            className='fill-gray-600 mx-3 rotate-90'
          >
            <path d='m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z'></path>
          </svg>
          <input
            type='text'
            placeholder='ស្វែងរក...'
            value={search}
            onChange={e => setSearch(e.target.value)}
            className='w-full outline-none border-none focus:border-none focus:ring-0 bg-transparent text-gray-600 text-sm'
          />
        </div>
        <div className='font-sans overflow-x-auto'>
          <table className='min-w-full border border-collapse divide-y divide-gray-200'>
            <thead className='bg-gray-100 whitespace-nowrap'>
              <tr>
                <th className='px-4 py-4 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
                  {`ឈ្មោះ`}
                </th>
                <th className='px-4 py-4 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
                  {`តម្លៃរាយ`}
                </th>
                <th className='px-4 py-4 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
                  {`តម្លៃដុំ`}
                </th>
                <th className='px-4 py-4 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
                  {``}
                </th>
              </tr>
            </thead>

            <tbody className='bg-white divide-y divide-gray-200 whitespace-nowrap'>
              {filteredProducts.length > 0 ? (
                filteredProducts.map(product => (
                  <tr key={`PRODUCT-${product.id}`}>
                    <td className='px-4 py-2 text-sm text-gray-800'>
                      {product.name}
                    </td>
                    <td className='px-4 py-2 text-sm text-gray-800'>
                      {product.retail_price ? product.retail_price * 4100 : 0} ៛
                    </td>
                    <td className='px-4 py-2 text-sm text-gray-800'>
                      {product.wholesale_price
                        ? product.wholesale_price * 4100
                        : 0}{' '}
                      ៛
                    </td>
                    <td className='px-4 py-2 text-sm text-gray-800'>
                      <div className='flex gap-2'>
                        <button
                          type='button'
                          onClick={() => addItem('retail', product)}
                          className='w-full px-4 py-2 rounded-lg text-sm tracking-wider border border-blue-700 outline-none bg-transparent hover:bg-gray-100 text-blue-700 transition-all duration-300'
                        >
                          លក់រាយ
                        </button>
                        <button
                          type='button'
                          onClick={() => addItem('wholesale', product)}
                          className='w-full px-4 py-2 rounded-lg text-white text-sm tracking-wider outline-none bg-blue-700 hover:bg-blue-800 active:bg-blue-700'
                        >
                          លក់ដុំ
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan='4'
                    className='px-4 py-2 text-center text-sm text-gray-500'
                  >
                    មិនមានទំនិញដែលស្វែងរក
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
      <section className='w-[40%] h-[calc(100vh-95px)] rounded-md bg-gray-200 px-4 py-3 flex flex-col gap-2'>
        <div className='flex justify-between h-8 items-center'>
          <div>1$ = 4100៛</div>
          <button
            type='button'
            onClick={() => clearOrder()}
            className='text-red-500'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0'
              />
            </svg>
          </button>
        </div>

        <section className='w-full rounded-md h-[calc(100%-120px)] bg-white overflow-y-scroll'>
          <div className='font-[sans-serif] overflow-x-auto'>
            <table className='min-w-full bg-white'>
              <thead className='bg-gray-800 whitespace-nowrap'>
                <tr>
                  <th className='p-2 text-left text-sm font-medium text-white'>
                    ឈ្មោះ
                  </th>
                  <th className='p-2 text-left text-sm font-medium text-white'>
                    ចំនួន
                  </th>
                  <th className='p-2 text-left text-sm font-medium text-white'>
                    តម្លៃ
                  </th>
                  <th className='p-2 text-left text-sm font-medium text-white'>
                    សរុប
                  </th>
                  <th className='p-2 text-left text-sm font-medium text-white'></th>
                </tr>
              </thead>

              <tbody className='whitespace-nowrap'>
                {orderLines.length > 0 &&
                  orderLines.map(line => (
                    <tr
                      key={`ORDERLINE-${line.id}`}
                      className='even:bg-blue-50'
                    >
                      <td className='p-2 text-sm text-black'>{line.name}</td>
                      <td className='p-2 text-sm text-black'>
                        <div>
                          <button
                            type='button'
                            className='flex items-center px-2.5 py-1.5 border border-gray-300 text-gray-800 text-xs outline-none bg-transparent rounded-md'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='w-2.5 fill-current'
                              viewBox='0 0 124 124'
                              onClick={() => decreaseQty(line)}
                            >
                              <path
                                d='M112 50H12C5.4 50 0 55.4 0 62s5.4 12 12 12h100c6.6 0 12-5.4 12-12s-5.4-12-12-12z'
                                data-original='#000000'
                              ></path>
                            </svg>

                            <input
                              className='mx-2.5 border-none outline-none max-w-6 text-center'
                              onChange={e => {
                                modifyQty(e.target.value, line)
                              }}
                              value={line.quantity}
                            />
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='w-2.5 fill-current'
                              viewBox='0 0 42 42'
                              onClick={() => {
                                increaseQty(line)
                              }}
                            >
                              <path
                                d='M37.059 16H26V4.941C26 2.224 23.718 0 21 0s-5 2.224-5 4.941V16H4.941C2.224 16 0 18.282 0 21s2.224 5 4.941 5H16v11.059C16 39.776 18.282 42 21 42s5-2.224 5-4.941V26h11.059C39.776 26 42 23.718 42 21s-2.224-5-4.941-5z'
                                data-original='#000000'
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </td>
                      <td className='p-2 text-sm text-black'>
                        {line.unit_price}
                      </td>
                      <td className='p-2 text-sm text-black'>
                        {line.unit_price && line.quantity
                          ? line.unit_price * line.quantity
                          : 0}
                      </td>
                      <td>
                        <button
                          type='button'
                          onClick={() => removeLine(line)}
                          className='text-red-500'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            className='size-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0'
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}

                {orderLines.length === 0 && (
                  <tr className='p-4'>
                    <td
                      colSpan='100%'
                      className='p-4 text-gray-500 text-center text-sm'
                    >
                      បញ្ចូលទំនិញដើម្បីចាប់ផ្ដើម
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        {orderLines.length > 0 && (
          <div className='flex flex-col gap-2 h-20 pt-2'>
            <div className='flex flex-row justify-between'>
              <span>តម្លៃសរុប​ (៛)</span>
              <span>{order.total_khr} ៛</span>
            </div>
            <div className='flex flex-row justify-between'>
              <span>តម្លៃសរុប​ ($)</span>
              <span>{order.total_usd} $</span>
            </div>
          </div>
        )}
      </section>
    </div>
  )
}

export default PosPage
