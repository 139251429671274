import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  VERIFY_AUTHENTICATION_SUCCESS,
  VERIFY_AUTHENTICATION_FAIL
} from '../actions/types'

const initialState = {
  isAuthenticated: null,
  error_message: ''
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case VERIFY_AUTHENTICATION_SUCCESS:
    case VERIFY_AUTHENTICATION_FAIL:
      return {
        ...state,
        isAuthenticated: payload
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        error_message: ''
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false
      }
    case LOGIN_FAIL:
      return {
        ...state,
        error_message: payload
      }
    case LOGOUT_FAIL:
      return state
    default:
      return state
  }
}
