import React, { useState, useEffect } from 'react'

const Products = () => {
  const [products, setProducts] = useState([
    {
      id: 1,
      name: 'ប្រេងឆា Healthy Chef',
      retail_uom: 'ដប',
      retail_price: 2,
      wholesale_price: 10,
      wholesale_uom: 'កេស'
    },
    {
      id: 2,
      name: 'ទឹកសុទ្ធ Crystal',
      retail_uom: 'ដប',
      retail_price: 1,
      wholesale_price: 5,
      wholesale_uom: 'កេស'
    },
    {
      id: 3,
      name: 'សាប៊ូ Lux',
      retail_price: 3,
      wholesale_price: 8
    }
  ])

  const [isOpen, setIsOpen] = useState(false)

  const createProduct = (event, product) => {
    event.preventDefault()
    console.log('Created product')
    closeModal()
  }

  const editProduct = (event, product) => {
    event.preventDefault()
    console.log('Edited Product')
    closeModal()
  }

  const openModal = () => {
    setIsOpen(true)
    console.log('open')
  }

  const closeModal = () => {
    setIsOpen(false)
    console.log('close')
  }

  const [search, setSearch] = useState('')
  const [filteredProducts, setFilteredProducts] = useState(products)

  useEffect(() => {
    const searchProducts = search => {
      if (!search.trim()) {
        setFilteredProducts(products) // If search is empty, show all products
        return
      }

      const lowerCaseSearch = search.toLowerCase()
      const filtered = products.filter(product =>
        product.name.toLowerCase().includes(lowerCaseSearch)
      )
      setFilteredProducts(filtered)
    }

    searchProducts(search)
  }, [search, products])

  return (
    <main className='flex flex-col gap-4'>
      <div
        className={`${
          isOpen ? 'fixed' : 'hidden'
        } inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]`}
      >
        <div className='w-full max-w-lg bg-white shadow-lg rounded-lg p-6 relative'>
          <div className='flex items-center pb-3 border-b border-gray-300'>
            <h3 className='text-gray-800 text-xl font-bold flex-1'>
              Modal Title
            </h3>
          </div>

          <form
            onSubmit={e => createProduct(e)}
            className='my-6 flex flex-col gap-4'
          >
            <div className='grid grid-cols-1'>
              <div className='relative flex items-center'>
                <input
                  type='text'
                  placeholder='ឈ្មោះ'
                  className='px-4 py-3 bg-[#f0f1f2] focus:bg-transparent text-black w-full text-sm border outline-[#007bff] rounded transition-all'
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#bbb'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='#bbb'
                  className='w-[18px] h-[18px] absolute right-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12'
                  />
                </svg>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <div className='relative flex items-center'>
                <input
                  type='number'
                  placeholder='តម្លៃរាយ'
                  className='px-4 py-3 bg-[#f0f1f2] focus:bg-transparent text-black w-full text-sm border outline-[#007bff] rounded transition-all'
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='#bbb'
                  className='w-[18px] h-[18px] absolute right-4'
                >
                  <path d='M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z' />
                  <path
                    fillRule='evenodd'
                    d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>
              <div className='relative flex items-center'>
                <input
                  type='text'
                  placeholder='ខ្នាត'
                  className='px-4 py-3 bg-[#f0f1f2] focus:bg-transparent text-black w-full text-sm border outline-[#007bff] rounded transition-all'
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#bbb'
                  stroke='#bbb'
                  className='w-[18px] h-[18px] absolute right-4'
                  viewBox='0 0 24 24'
                >
                  <circle cx='10' cy='7' r='6' data-original='#000000'></circle>
                  <path
                    d='M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z'
                    data-original='#000000'
                  ></path>
                </svg>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <div className='relative flex items-center'>
                <input
                  type='number'
                  placeholder='តម្លៃដុំ'
                  className='px-4 py-3 bg-[#f0f1f2] focus:bg-transparent text-black w-full text-sm border outline-[#007bff] rounded transition-all'
                />

                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='#bbb'
                  className='w-[18px] h-[18px] absolute right-4'
                >
                  <path d='M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z' />
                  <path
                    fillRule='evenodd'
                    d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>
              <div className='relative flex items-center'>
                <input
                  type='text'
                  placeholder='ខ្នាត'
                  className='px-4 py-3 bg-[#f0f1f2] focus:bg-transparent text-black w-full text-sm border outline-[#007bff] rounded transition-all'
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='#bbb'
                  stroke='#bbb'
                  className='w-[18px] h-[18px] absolute right-4'
                  viewBox='0 0 24 24'
                >
                  <circle cx='10' cy='7' r='6' data-original='#000000'></circle>
                  <path
                    d='M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z'
                    data-original='#000000'
                  ></path>
                </svg>
              </div>
            </div>
          </form>

          <div className='border-t border-gray-300 pt-6 flex justify-end gap-4'>
            <button
              type='button'
              onClick={() => closeModal()}
              className='px-4 py-2 rounded-lg text-gray-800 text-sm border-none outline-none tracking-wide bg-gray-200 hover:bg-gray-300 active:bg-gray-200'
            >
              Close
            </button>
            <button
              type='button'
              className='px-4 py-2 rounded-lg text-white text-sm border-none outline-none tracking-wide bg-blue-600 hover:bg-blue-700 active:bg-blue-600'
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <section className='flex justify-between gap-4'>
        <div className='w-full flex rounded-md border-2 overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 192.904 192.904'
            width='16px'
            className='fill-gray-600 mx-3 rotate-90'
          >
            <path d='m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z'></path>
          </svg>
          <input
            type='text'
            placeholder='ស្វែងរក...'
            value={search}
            onChange={e => setSearch(e.target.value)}
            className='w-full outline-none border-none focus:border-none focus:ring-0 bg-transparent text-gray-600 text-sm'
          />
        </div>

        <button
          onClick={() => openModal()}
          className='text-sm text-nowrap bg-green-400 text-white rounded-md px-4 py-2'
        >
          បញ្ចូលទំនិញថ្មី
        </button>
      </section>

      <table className='min-w-full border border-collapse divide-y divide-gray-200'>
        <thead className='bg-gray-100 whitespace-nowrap'>
          <tr>
            <th className='px-4 py-4 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
              {`ឈ្មោះ`}
            </th>
            <th className='px-4 py-4 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
              {`តម្លៃរាយ`}
            </th>
            <th className='px-4 py-4 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
              {`តម្លៃដុំ`}
            </th>
            <th className='px-4 py-4 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider'>
              {``}
            </th>
          </tr>
        </thead>

        <tbody className='bg-white divide-y divide-gray-200 whitespace-nowrap'>
          {filteredProducts.length > 0 ? (
            filteredProducts.map(product => (
              <tr key={`PRODUCT-${product.id}`}>
                <td className='px-4 py-2 text-sm text-gray-800'>
                  {product.name}
                </td>
                <td className='px-4 py-2 text-sm text-gray-800'>
                  {product.retail_price ? product.retail_price * 4100 : 0} ៛
                </td>
                <td className='px-4 py-2 text-sm text-gray-800'>
                  {product.wholesale_price ? product.wholesale_price * 4100 : 0}{' '}
                  ៛
                </td>
                <td className='px-4 py-2 text-sm text-gray-800'>
                  <button className='mr-4' title='Edit'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='w-5 fill-blue-500 hover:fill-blue-700'
                      viewBox='0 0 348.882 348.882'
                    >
                      <path
                        d='m333.988 11.758-.42-.383A43.363 43.363 0 0 0 304.258 0a43.579 43.579 0 0 0-32.104 14.153L116.803 184.231a14.993 14.993 0 0 0-3.154 5.37l-18.267 54.762c-2.112 6.331-1.052 13.333 2.835 18.729 3.918 5.438 10.23 8.685 16.886 8.685h.001c2.879 0 5.693-.592 8.362-1.76l52.89-23.138a14.985 14.985 0 0 0 5.063-3.626L336.771 73.176c16.166-17.697 14.919-45.247-2.783-61.418zM130.381 234.247l10.719-32.134.904-.99 20.316 18.556-.904.99-31.035 13.578zm184.24-181.304L182.553 197.53l-20.316-18.556L294.305 34.386c2.583-2.828 6.118-4.386 9.954-4.386 3.365 0 6.588 1.252 9.082 3.53l.419.383c5.484 5.009 5.87 13.546.861 19.03z'
                        data-original='#000000'
                      />
                      <path
                        d='M303.85 138.388c-8.284 0-15 6.716-15 15v127.347c0 21.034-17.113 38.147-38.147 38.147H68.904c-21.035 0-38.147-17.113-38.147-38.147V100.413c0-21.034 17.113-38.147 38.147-38.147h131.587c8.284 0 15-6.716 15-15s-6.716-15-15-15H68.904C31.327 32.266.757 62.837.757 100.413v180.321c0 37.576 30.571 68.147 68.147 68.147h181.798c37.576 0 68.147-30.571 68.147-68.147V153.388c.001-8.284-6.715-15-14.999-15z'
                        data-original='#000000'
                      />
                    </svg>
                  </button>
                  <button className='mr-4' title='Delete'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='w-5 fill-red-500 hover:fill-red-700'
                      viewBox='0 0 24 24'
                    >
                      <path
                        d='M19 7a1 1 0 0 0-1 1v11.191A1.92 1.92 0 0 1 15.99 21H8.01A1.92 1.92 0 0 1 6 19.191V8a1 1 0 0 0-2 0v11.191A3.918 3.918 0 0 0 8.01 23h7.98A3.918 3.918 0 0 0 20 19.191V8a1 1 0 0 0-1-1Zm1-3h-4V2a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H4a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM10 4V3h4v1Z'
                        data-original='#000000'
                      />
                      <path
                        d='M11 17v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Zm4 0v-7a1 1 0 0 0-2 0v7a1 1 0 0 0 2 0Z'
                        data-original='#000000'
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan='4'
                className='px-4 py-2 text-center text-sm text-gray-500'
              >
                មិនមានទំនិញដែលស្វែងរក
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </main>
  )
}

export default Products
