import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../actions/auth'

const Navbar = ({ isAuthenticated, logout }) => {
  const [currentTime, setCurrentTime] = useState('')

  const [settings, setSettings] = useState({
    opening_time: '09:00',
    closing_time: '21:00'
  })

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const updateTime = () => {
      const now = new Date()
      setCurrentTime(now.toLocaleTimeString()) // Formats time to a readable string`

      const currentHoursMinutes = `${String(now.getHours()).padStart(
        2,
        '0'
      )}:${String(now.getMinutes()).padStart(2, '0')}`

      if (
        currentHoursMinutes >= settings.opening_time &&
        currentHoursMinutes < settings.closing_time
      ) {
        setIsOpen(true) // "Shop is open" in Khmer
      } else {
        setIsOpen(false) // "Shop is closed" in Khmer
      }
    }

    updateTime() // Set initial time
    const interval = setInterval(updateTime, 1000) // Update every second

    return () => clearInterval(interval) // Clean up interval on component unmount
  }, [])

  return (
    <header className='border-b bg-white font-[sans-serif] tracking-wide relative z-50'>
      <section className='flex flex-wrap items-center gap-4 relative min-h-[70px] border-b'>
        <div
          className={`h-full flex-col gap-1 min-w-[150px] lg:absolute flex items-center justify-center mr-auto font-regular text-white ${
            isOpen ? 'bg-green-500' : 'bg-gray-400'
          } px-4 py-2`}
        >
          <p>{`${isOpen ? 'ហាងកំពុងបើក' : 'ហាងបានបិទ'}`}</p>
          <p>{currentTime}</p> {/* Display current time */}
        </div>

        <ul
          id='mainNav'
          className='flex space-x-8 lg:absolute lg:top-2/4 lg:left-2/4 lg:-translate-x-1/2 lg:-translate-y-1/2 max-lg:hidden'
        >
          <li>
            <NavLink
              to='/pos'
              className='hover:text-pink-500 text-gray-800 text-[15px] flex flex-col gap-2'
            >
              ប្រព័ន្ធលក់
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/dashboard'
              className='hover:text-pink-500 text-gray-800 text-[15px] flex flex-col gap-2'
            >
              ផ្ទាំងគ្រប់គ្រង
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/settings'
              className='hover:text-pink-500 text-gray-800 text-[15px] flex flex-col gap-2'
            >
              ការកំណត់
            </NavLink>
          </li>
        </ul>

        <div className='h-full min-w-[150px] justify-center text-white lg:absolute lg:right-0 px-5 flex items-center ml-auto bg-red-500'>
          <button className='flex gap-2' onClick={logout}>
            ចាកចេញ
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className='size-6'
            >
              <path
                fillRule='evenodd'
                d='M7.5 3.75A1.5 1.5 0 0 0 6 5.25v13.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V15a.75.75 0 0 1 1.5 0v3.75a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3V9A.75.75 0 0 1 15 9V5.25a1.5 1.5 0 0 0-1.5-1.5h-6Zm10.72 4.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 0-1.06Z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </div>
      </section>
    </header>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { logout })(Navbar)
