import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { login, checkAuthenticated } from '../actions/auth'
import CSRFToken from '../components/CSRFToken'

const LoginPage = ({
  login,
  isAuthenticated,
  checkAuthenticated,
  error_message
}) => {
  const [formData, setFormData] = useState({
    password: ''
  })

  const { password } = formData

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = e => {
    e.preventDefault()

    login(password)
  }

  useEffect(() => {
    checkAuthenticated()
  }, [checkAuthenticated])

  if (isAuthenticated) {
    return <Navigate to='/dashboard' />
  }

  return (
    <form
      onSubmit={e => onSubmit(e)}
      className='w-full h-full flex flex-col gap-4 items-center justify-center'
    >
      <CSRFToken />
      <h1 className='text-lg font-semibold'>បញ្ចូលពាក្យសម្ងាត់</h1>
      {error_message && (
        <span className='text-sm text-red-400'>{error_message}</span>
      )}
      <div>
        <input
          type='password'
          onChange={e => onChange(e)}
          className="outline-none focus:outline-none"
          name='password'
          value={password}
        />
        <button className='hidden' type='submit'>
          Enter
        </button>
      </div>
    </form>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error_message: state.auth.error_message
})

export default connect(mapStateToProps, { login, checkAuthenticated })(
  LoginPage
)
